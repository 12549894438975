import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../reportlost.component.css";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import { ToggleButton } from "@material-ui/lab";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import axios from "axios";
import Footer from "../../footer/footer.component.jsx";
import MapContainer from "../testMap";
import "date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
// import Userimage from "../../../assets/user2.png";
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Navbar from "../../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../../api/index.js";

import { Tabs } from "@material-ui/core";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  RadioGroup,
  useRadioGroup,
  Tab,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Radio from "@material-ui/core/Radio";
import { styled } from "@material-ui/core/styles";
import MuiPhoneInput from "material-ui-phone-number";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

const baseURL = switchUrls("human");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
let token = usertoken;

function getSteps() {
  return ["Loss Details", "Location", "Date & Time of Loss", "Human Details"];
}

const steps = getSteps();

export default class EditReportLost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebar: true,
      subMenu: true,
      alignment: "left",
      tag_number: this.props.match.params.tag_number,
      lf_status: "lost",
      valuable_type: "",
      name: "",
      blood_group: "",
      gender: "",
      date_of_birth: "",
      age: "",
      identity_mark: "",
      skin_color: "",
      guardian_name: "",
      guardian_email: "",
      guardian_mobile: "",
      guardian_code: "",
      veternity: "",
      pet_gender: "",
      identification_mark: "",
      parent_firstname: "",
      parent_lastname: "",
      phone_one: "",
      phone_two: "",
      phone_three: "",
      parent_email: "",
      parent_altemail: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timestart: "",
      lost_timeend: "",
      latitude: "",
      longitude: "",
      dr_phone: "",
      lost_comment: "",
      pet_imagelocation: "",
      snackbaropen: false,
      editlocation: false,
      snackbarmsg: "",
      activeStep: 0,
      typeof_member: "",
      first_name : "",
      last_name : "",
      guardian_firstname:"",
      guardian_lastname:"",
      typeof_guardian:"",
      guardian_firstname : "",
      guardian_lastname : "",


      
    };

    // this.getCoordinates = this.getCoordinates.bind(this);
    // this.getlatestposition = this.getlatestposition.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
  }

  handleAlignment = (event, alignment) => this.setState({ alignment });

  componentDidMount() {
    if (navigator.geolocation) {
      // navigator.geolocation.getCoordinates();
      // navigator.geolocation.getCurrentPosition(this.getCoordinates);
    } else {
      alert("Geolocation is not supported by this browser.");
    }

    const access_token = token;
    const getURL =
      `${baseURL}/api/human/getLostDetails/` +
      this.props.match.params.tag_number;
    axios
      .get(getURL, {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const lastIndexData =
          response.data.length > 0 ? response.data.length - 1 : 0;
        console.log(response);
        this.setState({
          lost_date: response.data[lastIndexData]?.lost_date,
          reward: response.data[lastIndexData]?.reward,
          latitude: response.data[lastIndexData]?.lost_latitude,
          longitude: response.data[lastIndexData]?.lost_longitude,
          rewardcurrency: response.data[lastIndexData]?.reward_currency,
          tag_status: response.data[lastIndexData]?.tag_status,
          lost_comment: response.data[lastIndexData]?.lost_comment,
          lost_timestart: response.data[lastIndexData]?.lost_timestart,
          lost_timeend: response.data[lastIndexData]?.lost_timeend,
          _id : response.data[lastIndexData]?._id
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/human/getguardianDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        // console.log(response, "res");
        this.setState({
          uid: response.data.uid,
          typeof_member: response?.data?.typeof_member,
          name: response?.data?.name,
          blood_group: response?.data?.blood_group,
          gender: response?.data?.gender,
          date_of_birth: response?.data?.date_of_birth,
          age: response?.data?.age,
          identity_mark: response?.data?.identity_mark,
          skin_color: response?.data?.skin_color,
          guardian_name: response?.data?.guardian_name || "",
          phone_one: response?.data?.guardian_mobile || "",
          phone_two: response?.data?.guardian_alternate_mobile,
          guardian_email: response?.data?.guardian_email,
          guardian_alternate_email: response?.data?.guardian_alternate_email,
          guardian_country: response?.data?.guardian_country,
          guardian_address1: response?.data?.guardian_address1,
          guardian_address2: response?.data?.guardian_address2,
          guardian_state: response?.data?.guardian_state,
          guardian_city: response?.data?.guardian_city,
          guardian_pin: response?.data?.guardian_pin,
          guardian_gender: response?.data?.guardian_gender,
          guardian_identity_mark: response?.data?.guardian_identity_mark,
          guardian_code: response?.data?.guardian_code,
          guardian_alternatecode: response?.data?.guardian_alternatecode,
          first_name : response?.data?.first_name,
          last_name : response?.data?.last_name,
          guardian_firstname : response?.data?.guardian_firstname,
          guardian_lastname : response?.data?.guardian_lastname,
          typeof_guardian : response?.data?.typeof_guardian,
        

  
          
        });
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  sendlatitude = (data) => {
    console.log("data", data);
    this.setState({
      latitude: data.lat,
      longitude: data.lng,
    });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  dateChangeHandler = (Date) => {
    console.log(Date.target.value);
    this.setState({ [Date.target.name]: Date.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    const tag_number = this.state.tag_number;
    axios
      .post(`${baseURL}/api/human/editlostdetails/` + tag_number, this.state, {
        headers: { Authorization: token },
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        if (response.data === "Lost details updated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
          window.location.href = "/#/human-poster/" + tag_number;
        } else {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Edit lost Details Failed",
          });
          window.location.href = "/dashboard/" + localStorage.getItem("uid");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Edit lost Details Failed",
        });
      });
  };
  getStepContent = (stepIndex) => {
    const {
      lost_text,
      lost_date,
      latitude,
      longitude,
      lost_timestart,
      lost_timeend,
      alignment,
      lost_comment,
      tag_number,
      valuable_type,
      parent_firstname,
      parent_lastname,
      phone_one,
      phone_two,
      parent_email,
      parent_altemail,
      reward,
      rewardcurrency,
      name,
      blood_group,
      gender,
      date_of_birth,
      age,
      identity_mark,
      skin_color,
      guardian_name,
      guardian_email,
      guardian_mobile,
      guardian_alternate_mobile,
      guardian_alternatecode,
      guardian_code,
      veternity,
      dr_phone,
      phone_three,
      editlocation,
      typeof_member,
      first_name,
      last_name,
      guardian_firstname,
      guardian_lastname,
      typeof_guardian

      
    } = this.state;

    // console.log("state: ", this.state)
    switch (stepIndex) {
      case 0:
        return (
          <div className="reportlost-loss-details">
            <textarea
              rows="4"
              className=""
              name="lost_comment"
              placeholder="Give a brief account of loss. [Eg] Were you in a cab?"
              value={lost_comment}
              onChange={this.changeHandler}
            ></textarea>

            <div className="reportlost-loss-details-btns">
              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>

              <Button
                variant="contained"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>
            </div>
          </div>
        );
      case 1:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <div className="row mapcontainer-row">
                <MapContainer
                  className="googlemaps"
                  sendlatitude={this.sendlatitude}
                ></MapContainer>
              </div>
            </div>

            <div className="reportlost-location-input-cnt">
              <div className="reportlost-location-input-cnt-lat">
                <span className="">Latitude</span>

                <input
                  type="text"
                  className="reportlost-location-input"
                  value={latitude}
                  name="latitude"
                  onChange={this.changeHandler}
                  readOnly
                />
              </div>

              <div className="reportlost-location-input-cnt-lon">
                <span className="">Longitude</span>

                <input
                  type="text"
                  className="reportlost-location-input"
                  value={longitude}
                  name="longitude"
                  onChange={this.changeHandler}
                  readOnly
                />
              </div>
            </div>

            <div className="reportlost-location-btns">
              <Button variant="contained" onClick={this.handleBack}>
                Back
              </Button>

              <Button
                variant="contained"
                value="right"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>

              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="date-time-loss">
            <div className="lost-div">
              <TextField
                className="lost-textfield"
                variant="outlined"
                label="Lost Date"
                name="lost_date"
                type="date"
                onChange={this.dateChangeHandler}
                value={lost_date}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </div>

            <h5 className="lost-time-heading">
              Set indicative time range of loss
            </h5>
            <div className="lost-div">
              <div className="lost-textfield">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label="Lost Time 1"
                      value={lost_timestart}
                      onChange={(timeValue) => {
                        this.setState({ lost_timestart: timeValue });
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="lost-textfield">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label="Lost Time 2"
                      value={lost_timeend}
                      onChange={(timeValue) => {
                        this.setState({lost_timeend : timeValue });
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>

            <div className="date-time-loss-btns">
              <Button variant="contained" onClick={this.handleBack}>
                Back
              </Button>

              <Button
                variant="contained"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>

              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="reportlost-container">
            <div className="reportlost-container-pet-details-span">
              <h4>Human Details</h4>

              {/* tag number and valuable type */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    variant="outlined"
                    label="Tag Number"
                    value={
                      tag_number && tag_number !== "-" ? tag_number : "N/A"
                    }
                    required
                    inputProps={{
                      readOnly: true,
                      startAdornment: (
                        <img
                          src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                          style={{ width: "30px", height: "30px" }}
                        ></img>
                      ),
                    }}
                  />
                </div>
                <div className="reportlost-input">
                  <TextField
                    variant="outlined"
                    label="Relation"
                    value={typeof_guardian}
                    required
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>

              {/* name and breed */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Name "
                    name="name"
                    value={first_name ? first_name + " " +  last_name : name}
                    required
                    inputProps={{
                      max: 5,
                      min: 1,
                    }}
                  />
                </div>
                <div className="reportlost-input">
                  <TextField
                    label="Gender"
                    type="text"
                    variant="outlined"
                    maxLength="3"
                    name="gender"
                    value={gender}
                    required
                  ></TextField>
                </div>
              </div>

              {/*dob and age */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    label="Date of Birth"
                    variant="outlined"
                    type="date"
                    name="date_of_birth"
                    value={date_of_birth}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date(),
                    }}
                    required
                  />
                </div>
                <div className="reportlost-input">
                  <TextField
                    label="Age"
                    type="number"
                    variant="outlined"
                    value={age}
                    required
                  />
                </div>
              </div>

              {/* blood grp and mark */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    label="Blood Group"
                    value={blood_group}
                    variant="outlined"
                  ></TextField>
                </div>

               {
                identity_mark ?  <div className="reportlost-input">
                <TextField
                  label="Identification Mark"
                  variant="outlined"
                  type="text"
                  value={identity_mark}
                  required
                />
              </div> : null
               }
              </div>
            </div>

            <div className="reportlost-container-pet-parent-details-span">
              <h4 className="">Guardian Details</h4>

              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    type="text"
                    variant="outlined"
                    label="First Name"
                    name="guardian_name"
                    value={guardian_firstname ? guardian_firstname + " " + guardian_lastname : guardian_name} 
                  />
                </div>
                <div className="reportlost-input">
                  <TextField
                    type="email"
                    variant="outlined"
                    label="Email"
                    name="guardian_email"
                    value={guardian_email}
                    required
                  />
                </div>
              </div>

              {/* email and phone1 */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <MuiPhoneInput
                    label="Emergency Phone Number 1"
                    defaultCountry="in"
                    value={`+${guardian_code} ${phone_one}`}
                    regions={[
                      "america",
                      "caribbean",
                      "asia",
                      "middle-east",
                      "oceania",
                      "europe",
                      "africa",
                    ]}
                    name="phone_one"
                    variant="outlined"
                    required
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="personal-details-icon"
                        >
                          <ContactPhoneIcon />
                        </IconButton>
                      ),
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="reportlost-input">
                  <MuiPhoneInput
                    label="Emergency Phone Number 2"
                    defaultCountry="in"
                    value={`+${guardian_alternatecode} ${phone_two}`}
                    regions={[
                      "america",
                      "caribbean",
                      "asia",
                      "middle-east",
                      "oceania",
                      "europe",
                      "africa",
                    ]}
                    name="phone_two"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="personal-details-icon"
                        >
                          <ContactPhoneIcon />
                        </IconButton>
                      ),
                      readOnly: true,
                    }}
                  />
                </div>
              </div>

              {/* phone2 and phone3  */}
              <div className="reportlost-one-line"></div>
            </div>

            <div className="reportlost-container-owner-reward-span">
              <h4>
                <i className="fa fa-gift fa-2x"></i>
                <span>Owner Reward</span>
              </h4>

              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    select
                    label="Currency type"
                    name="rewardcurrency"
                    value={rewardcurrency}
                    onChange={this.changeHandler}
                  >
                    <MenuItem hidden></MenuItem>
                    <MenuItem value="Rupees">Indian Rupees</MenuItem>
                    <MenuItem value="U.S. dollar">U.S. dollar</MenuItem>
                    <MenuItem value="Euro">Euro</MenuItem>
                    <MenuItem value="Dirham">Dirham</MenuItem>
                    <MenuItem value="Dinar">Dinar</MenuItem>
                    <MenuItem value="Yen">Yen</MenuItem>
                    <MenuItem value="Afghani">Afghani</MenuItem>
                    <MenuItem value="Lek">Lek</MenuItem>
                    <MenuItem value="New Kwanza">New Kwanza</MenuItem>
                    <MenuItem value="Dram">Dram</MenuItem>
                    <MenuItem value="Bahamian dollar">Bahamian dollar</MenuItem>
                    <MenuItem value="Bahrain dinar">Bahrain dinar</MenuItem>
                    <MenuItem value="Taka">Taka</MenuItem>
                    <MenuItem value="Barbados dollar">Barbados dollar</MenuItem>
                    <MenuItem value="Belorussian ruble">
                      Belorussian ruble
                    </MenuItem>
                    <MenuItem value="Belize dollar">Belize dollar</MenuItem>
                    <MenuItem value="Ngultrum">Ngultrum</MenuItem>
                    <MenuItem value="Boliviano">Boliviano</MenuItem>
                    <MenuItem value="Convertible Mark">
                      Convertible Mark
                    </MenuItem>
                    <MenuItem value="Pula">Pula</MenuItem>
                    <MenuItem value="Real">Real</MenuItem>
                    <MenuItem value="Brunei dollar">Brunei dollar</MenuItem>
                    <MenuItem value="Lev">Lev</MenuItem>
                    <MenuItem value="Burundi franc">Burundi franc</MenuItem>
                    <MenuItem value="Riel">Riel</MenuItem>
                    <MenuItem value="Canadian dollar">Canadian dollar</MenuItem>
                    <MenuItem value="Cape Verdean escudo">
                      Cape Verdean escudo
                    </MenuItem>
                    <MenuItem value="Chilean Peso">Chilean Peso</MenuItem>
                    <MenuItem value="Chinese Yuan">Chinese Yuan</MenuItem>
                    <MenuItem value="Colombian Peso">Colombian Peso</MenuItem>
                    <MenuItem value="Franc">Franc</MenuItem>
                    <MenuItem value="United States dollar">
                      United States dollar
                    </MenuItem>
                    <MenuItem value="Colón">Colón</MenuItem>
                    <MenuItem value="Croatian">Croatian</MenuItem>
                    <MenuItem value="Cuban Peso">Cuban Peso</MenuItem>
                    <MenuItem value="Koruna">Koruna</MenuItem>
                    <MenuItem value="Danish Krone">Danish Krone</MenuItem>
                    <MenuItem value="Djiboutian franc">
                      Djiboutian franc
                    </MenuItem>
                    <MenuItem value="Dominican Peso">Dominican Peso</MenuItem>
                    <MenuItem value="Egyptian pound">Egyptian pound</MenuItem>
                    <MenuItem value="Colón; U.S. dollar">
                      Colón; U.S. dollar
                    </MenuItem>
                    <MenuItem value="Nakfa">Nakfa</MenuItem>
                    <MenuItem value="Birr">Birr</MenuItem>
                    <MenuItem value="Fiji dollar">Fiji dollar</MenuItem>
                    <MenuItem value="Dalasi">Dalasi</MenuItem>
                    <MenuItem value="Lari">Lari</MenuItem>
                    <MenuItem value="Cedi">Cedi</MenuItem>
                    <MenuItem value="Quetzal">Quetzal</MenuItem>
                    <MenuItem value="Guinean franc">Guinean franc</MenuItem>
                    <MenuItem value="Guyanese dollar">Guyanese dollar</MenuItem>
                    <MenuItem value="Gourde">Gourde</MenuItem>
                    <MenuItem value="Lempira">Lempira</MenuItem>
                    <MenuItem value="Forint">Forint</MenuItem>
                    <MenuItem value="Icelandic króna">Icelandic króna</MenuItem>
                    <MenuItem value="Rupiah">Rupiah</MenuItem>
                    <MenuItem value="Iraqi Dinar">Iraqi Dinar</MenuItem>
                    <MenuItem value="Shekel">Shekel</MenuItem>
                    <MenuItem value="Jamaican dollar">Jamaican dollar</MenuItem>
                    <MenuItem value="Jordanian dinar">Jordanian dinar</MenuItem>
                    <MenuItem value="Tenge">Tenge</MenuItem>
                    <MenuItem value="Kenya shilling">Kenya shilling</MenuItem>
                    <MenuItem value="Kiribati dollar">Kiribati dollar</MenuItem>
                    <MenuItem value="Won">Won</MenuItem>
                    <MenuItem value="Kuwaiti Dinar">Kuwaiti Dinar</MenuItem>
                    <MenuItem value="Som">Som</MenuItem>
                    <MenuItem value="New Kip">New Kip</MenuItem>
                    <MenuItem value="Lats">Lats</MenuItem>
                    <MenuItem value="Lebanese pound">Lebanese pound</MenuItem>
                    <MenuItem value="Maluti">Maluti</MenuItem>
                    <MenuItem value="Liberian dollar">Liberian dollar</MenuItem>
                    <MenuItem value="Libyan dinar">Libyan dinar</MenuItem>
                    <MenuItem value="Litas">Litas</MenuItem>
                    <MenuItem value="Denar">Denar</MenuItem>
                    <MenuItem value="Malagasy Ariary">Malagasy Ariary</MenuItem>
                    <MenuItem value="Ringgit">Ringgit</MenuItem>
                    <MenuItem value="Rufiyaa">Rufiyaa</MenuItem>
                    <MenuItem value="Ouguiya">Ouguiya</MenuItem>
                    <MenuItem value="Mauritian rupee">Mauritian rupee</MenuItem>
                    <MenuItem value="Mexican peso">Mexican peso</MenuItem>
                    <MenuItem value="Leu">Leu</MenuItem>
                    <MenuItem value="Togrog">Togrog</MenuItem>
                    <MenuItem value="Metical">Metical</MenuItem>
                    <MenuItem value="Kyat">Kyat</MenuItem>
                    <MenuItem value="Namibian dollar">Namibian dollar</MenuItem>
                    <MenuItem value="Australian dollar">
                      Australian dollar
                    </MenuItem>
                    <MenuItem value="Nepalese rupee">Nepalese rupee</MenuItem>
                    <MenuItem value="New Zealand dollar">
                      New Zealand dollar
                    </MenuItem>
                    <MenuItem value="Gold cordoba">Gold cordoba</MenuItem>
                    <MenuItem value="Naira">Naira</MenuItem>
                    <MenuItem value="Norwegian krone">Norwegian krone</MenuItem>
                    <MenuItem value="Omani rial">Omani rial</MenuItem>
                    <MenuItem value="Pakistani rupee">Pakistani rupee</MenuItem>
                    <MenuItem value="Palestine Pound">Palestine Pound</MenuItem>
                    <MenuItem value="Balboa; U.S. dollar">
                      Balboa; U.S. dollar
                    </MenuItem>
                    <MenuItem value="Kina">Kina</MenuItem>
                    <MenuItem value="Guaraní">Guaraní</MenuItem>
                    <MenuItem value="Nuevo sol (1991)">
                      Nuevo sol (1991)
                    </MenuItem>
                    <MenuItem value="Peso">Peso</MenuItem>
                    <MenuItem value="Zloty">Zloty</MenuItem>
                    <MenuItem value="Qatari riyal">Qatari riyal</MenuItem>
                    <MenuItem value="Romanian Rupee">Romanian Rupee</MenuItem>
                    <MenuItem value="Ruble">Ruble</MenuItem>
                    <MenuItem value="Rwandan franc">Rwandan franc</MenuItem>
                    <MenuItem value="East Caribbean dollar">
                      East Caribbean dollar
                    </MenuItem>
                    <MenuItem value="Tala">Tala</MenuItem>
                    <MenuItem value="Dobra">Dobra</MenuItem>
                    <MenuItem value="Riyal">Riyal</MenuItem>
                    <MenuItem value="Serbian Dinar">Serbian Dinar</MenuItem>
                    <MenuItem value="Seychelles rupee">
                      Seychelles rupee
                    </MenuItem>
                    <MenuItem value="Leone">Leone</MenuItem>
                    <MenuItem value="Singapore dollar">
                      Singapore dollar
                    </MenuItem>
                    <MenuItem value="Solomon Islands dollar">
                      Solomon Islands dollar
                    </MenuItem>
                    <MenuItem value="Somali shilling">Somali shilling</MenuItem>
                    <MenuItem value="Rand">Rand</MenuItem>
                    <MenuItem value="Sri Lankan rupee">
                      Sri Lankan rupee
                    </MenuItem>
                    <MenuItem value="Sudanese Pound">Sudanese Pound</MenuItem>
                    <MenuItem value="Surinamese dollar">
                      Surinamese dollar
                    </MenuItem>
                    <MenuItem value="Lilangeni">Lilangeni</MenuItem>
                    <MenuItem value="Krona">Krona</MenuItem>
                    <MenuItem value="Swiss franc">Swiss franc</MenuItem>
                    <MenuItem value="Syrian pound">Syrian pound</MenuItem>
                    <MenuItem value="Taiwan dollar">Taiwan dollar</MenuItem>
                    <MenuItem value="somoni">somoni</MenuItem>
                    <MenuItem value="Tanzanian shilling">
                      Tanzanian shilling
                    </MenuItem>
                    <MenuItem value="Baht">Baht</MenuItem>
                    <MenuItem value="CFA Franc">CFA Franc</MenuItem>
                    <MenuItem value="Pa’anga">Pa’anga</MenuItem>
                    <MenuItem value="Trinidad and Tobago dollar">
                      Trinidad and Tobago dollar
                    </MenuItem>
                    <MenuItem value="Tunisian dinar">Tunisian dinar</MenuItem>
                    <MenuItem value="Turkish lira (YTL)">
                      Turkish lira (YTL)
                    </MenuItem>
                    <MenuItem value="Manat">Manat</MenuItem>
                    <MenuItem value="Tuvaluan Dollar">Tuvaluan Dollar</MenuItem>
                    <MenuItem value="Ugandan new shilling">
                      Ugandan new shilling
                    </MenuItem>
                    <MenuItem value="Hryvnia">Hryvnia</MenuItem>
                    <MenuItem value="U.A.E. Dirham">U.A.E. Dirham</MenuItem>
                    <MenuItem value="Pound sterling">Pound sterling</MenuItem>
                    <MenuItem value="Dollar">Dollar</MenuItem>
                    <MenuItem value="Uruguay peso">Uruguay peso</MenuItem>
                    <MenuItem value="Uzbekistani sum">Uzbekistani sum</MenuItem>
                    <MenuItem value="Vatu">Vatu</MenuItem>
                    <MenuItem value="Bolivar">Bolivar</MenuItem>
                    <MenuItem value="Dong">Dong</MenuItem>
                    <MenuItem value="Rial">Rial</MenuItem>
                    <MenuItem value="Kwacha">Kwacha</MenuItem>
                  </TextField>
                </div>

                <div className="reportlost-input">
                  <TextField
                    label="Reward"
                    type="number"
                    variant="outlined"
                    name="reward"
                    value={reward}
                    onChange={this.changeHandler}
                  />
                </div>
              </div>

              <div className="reportlost-container-owner-reward-btns">
                <Button variant="contained" onClick={this.handleBack}>
                  Back
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  style={{ backgroundColor: "#ffc107" }}
                >
                  Submit
                </Button>

                <Button
                  type="button"
                  onClick={() => {
                    window.location.href =
                      "/dashboard/" + localStorage.getItem("uid");
                  }}
                  variant="contained"
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  handleStepChange = (e, stepCount) => {
    // Implement your logic for section change here
    console.log(`Changing to section: ${stepCount}`);
    this.setState({ activeStep: stepCount });
  };

  handleNext = () => {
    this.setState((prevActiveStep) => {
      console.log("prev", prevActiveStep);
      return {
        activeStep: prevActiveStep.activeStep + 1,
      };
    });
  };

  handleBack = () => {
    this.setState((prevActiveStep) => {
      return {
        activeStep: prevActiveStep.activeStep - 1,
      };
    });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  render() {
    const {
      sidebar,
      subMenu,
      alignment,
      tag_number,
      valuable_type,
      parent_firstname,
      parent_lastname,
      phone_one,
      phone_two,
      parent_email,
      parent_altemail,
      reward,
      rewardcurrency,
      pet_age,
      pet_name,
      pet_breed,
      pet_gender,
      pet_color,
      pet_dob,
      pet_bloodgrp,
      pet_ident,
      veternity,
      dr_phone,
      phone_three,
    } = this.state;

    return (
      <>
        <Navbar />

        <div
          className={`reportLost-main-wrapper ${
            this.state.activeStep !== 3 ? "bg-gray-height" : ""
          }`}
        >
          <div className="reportLost-left-wrapper">
            <div className="reportLost-sidebar-toggle-btn-wrapper">
              <button
                className="reportLost-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "reportLost-sidebar-container-hide"
                  : "reportLost-sidebar-container"
              }
            >
              <button
                className="reportLost-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "reportLost-sidebar-menu-ul-hide"
                    : "reportLost-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

            

              {/* <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/calendar/" + this.props.match.params.tag_number;
                }}
              >
                CALENDAR
              </button> */}

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/guardian-details/" + this.props.match.params.tag_number;
                }}
              >
                GUARDIAN DETAILS
              </button>

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/human-details/" + this.props.match.params.tag_number;
                }}
              >
                HUMAN DETAILS
              </button>

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/human/medical-dashboard/" +
                    this.props.match.params.tag_number;
                }}
              >
                MEDICAL DETAILS
              </button>
            </div>
          </div>

          <div className="reportLost-right-wrapper">
            <div className="reportLost-main-form-div">
              <form onSubmit={this.submitHandler} className="main-form">
                {/* <Stepper
                  activeStep={this.state.activeStep}
                  alternativeLabel
                  className=""
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper> */}
                <div className="tabs-div">
                  <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={this.state.activeStep}
                    onChange={this.handleStepChange}
                  >
                    {steps.map((label, index) => {
                      return <Tab label={label} key={index + label}></Tab>;
                    })}
                  </Tabs>
                </div>

                <div
                  className="container uppercontainer bg-white px-0 p-info text-center reportlostheight"
                  style={{
                    height: "100%",
                  }}
                >
                  {this.getStepContent(this.state.activeStep)}
                </div>

                <Snackbar
                  open={this.state.snackbaropen}
                  autoHideDuration={3000}
                  onClose={this.snackbarClose}
                  message={this.state.snackbarmsg}
                  action={[
                    <IconButton
                      arial-label="Close"
                      color="inherit"
                      onClick={this.snackbarClose}
                    >
                      X
                    </IconButton>,
                  ]}
                />
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
